.billing-rev-container{
    position: relative;
    left: 30px;
    top: 20px;

}
.billing-rev-buttons{
    display: flex;
    padding: 4px;
    width: 520px;
    margin-bottom: 20px;
    justify-content: space-between;
    border-radius: 9px;
    background-color: #DEAC87;
}
.billing-rev-buttons button{
    font-size: 15px;
    padding: 6px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    cursor: pointer;
}
.billing-rev-buttons button:hover{
    background-color: #834920;
}
.billing-rev-buttons button:focus{
    background-color: #834920;
}
.billing-rev-content{
    width: 90%;
}
.billing-rev-date-heading{
    display: flex;
    gap: 230px;
    align-items: center;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 16px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.billing-rev-inputs{
    display: flex;
}
.rev-datepicker {
    display: flex;
    align-items: center;
    gap: 20px; 
    position: relative; 
}

.billing-rev-img {
    position: absolute;
    top: 50%; 
    transform: translateY(-50%); 
    right: 10px; 
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #154674;
    z-index: 1;
    pointer-events: none; 
}

.custom-date-picker {
    border: 1px solid #F0F0F0;
    background-color: #FAFAFA;
    box-shadow: 0px 0px 3px 0px #54545440;
    padding: 12px 40px 12px 18px; 
    border-radius: 5px;
    width: 100%; 
    position: relative; 
    outline: none; 
    cursor: pointer;
}

.custom-date-picker:focus {
    border-color: #154674; 
    box-shadow: 0 0 5px #154674; 
}

.billing-rev-search{
    border: 1px solid #F0F0F0;
    background-color: #FAFAFA;
    width: 329px;
    cursor:  text;
    height: Fixed (40px)px;
    padding: 12px 18px 12px 38px;
    border-radius: 5px;
    margin-right: 5px;
}
.billing-rev-s-icon img{
    position: relative;
    left: 30px;
    top: 10px;
    
}
.billing-rev-s-btn-icon{
    height: 40px;
    width: 38px;
    background-color: #154674;
    border-radius: 5px;
    /* z-index: 10; */
}