.leave-form1 .btn.custom-btn {
  background-color: #e3b075;
  border-color: #e3b075;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  /* padding: .75rem 2.2rem; */
  cursor: pointer;
}

.leave-form1 .btn.custom-btn:hover {
  background-color: #f5a623;
  border-color: #f5a623;
}

/* Container for Select roles */
.selectRole {
  margin-top: 1rem;
}

.role-item {
  background-color: #f8f9fa; /* Light gray background */
  border: 1px solid #dee2e6; /* Border color */
  min-width: 100px; /* Ensures uniform sizing */
  text-align: center; /* Centers the text */
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

/* Hover effect */
.role-item:hover {
  background-color: #e9ecef;
  border-color: #adb5bd;
}

/* Checkbox alignment */
.role-item .form-check-input {
  margin-top: 0;
}

/* Role label styling */
.role-item label {
  font-size: 14px; /* Slightly smaller text */
  font-weight: 500; /* Medium weight for better readability */
  color: #495057; /* Subtle dark color */
}
