.therapist-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background-color: #f8f8f8;
}

.therapist-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.therapist-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.therapist-title {
  font-size: 0.9rem;
  color: #555555;
  margin: 0;
}

.therapist-name {
  font-size: 1rem;
  font-weight: bold;
  color: #b46934;
  margin: 0;
}

.card-center,
.card-right {
  text-align: center;
}

.label {
  font-size: 0.9rem;
  color: #555555;
  margin: 0;
}

.value {
  font-size: 1rem;
  font-weight: bold;
  color: #333333;
  margin: 0;
}

.schedule-btn {
  background-color: #555555;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.schedule-btn:hover {
  background-color: #444444;
}

.shift-selection {
  margin-top: 1rem;
  border-top: 1px solid #e0e0e0;
  padding-top: 1rem;
}

.shift-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.shift-btn {
  background-color: #f8f8f8;
  color: #333333;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 0.5rem;
  cursor: pointer;
  text-align: center;
}

.shift-btn.active {
  background-color: #b46934;
  color: white;
  border-color: #b46934;
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.cancel-btn,
.confirm-btn {
  border: none;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.cancel-btn {
  background-color: transparent;
  color: #b46934;
  border: 1px solid #b46934;
  margin-right: 5px;
}

.confirm-btn {
  background-color: #b46934;
  color: white;
}
