.prescription-physician-details{
    display:flex;
    flex-direction:column;
    /* text-align:left; */
}

.prescription-physician-details .physician-name{
    color: #DA8601;
    /* font-family: Inter; */
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 800;
    /* line-height: 1.125rem; */
}

.prescription-physician-details .physician-detail{
    color: #646464;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
}

.prescription-patient-details{
    display:flex;
    align-items:center;
}

.prescription-patient-details .title{
    color: #646464;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
}

.prescription-patient-details .colon{
    color: #646464;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
}

.prescription-patient-details .detail{
    color: #646464;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
}

.prescription-patient-details .token{
    color: #646464;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
}