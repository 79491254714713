
.img-div{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    
}
.profile{
    font-size: 12px;
}
.input-main input {
  height: 25px;
  font-size: 10px;
  background-color: rgb(232, 226, 226);
}
.shadowed-div h4{
    color: rgb(113, 109, 109);
}

.input-main label {
  font-size: 10px;
  color: rgb(211, 206, 206);
}

.shadowed-div {
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
  padding: 16px;
  border-radius: 4px;
}

.change-password {
  font-size: 10px;
  cursor: pointer;
  margin-left: 5px;
}
.user-delete {
  font-size: 10px;
  color: rgb(240, 60, 60);
  cursor: pointer;
  margin-left: 5px;
}
.delete-forever{
    color: rgb(240, 60, 60);
}
