.therapy-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1rem 1rem 0.5rem 1rem; 
  width: 71rem;
  margin: auto;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 8px;
}
.card-row .label{
  font-weight: normal;
}

.label {
  font-weight: bold;
  color: #333;
}

.value {
  color: #000;
}

.btn-grey {
  background-color: grey;
  color: white;
  border: none;
}

.btn-grey:hover {
  background-color: darkgrey;
}

.calendar-row td {
  padding: 16px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
}
.calendar-container {
  display: flex;
  flex-direction: column;
}
.calendar-button-container {
  flex-shrink: 0;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin: 8rem 0 0 2rem;
}

.selected-date-text,
.selected-date {
  margin-bottom: 10px;
}
