:root{
  --button-background: #1f5ea7;
  --button-padding:10px 50px 10px 50px;
  --button-radius:6px;
  --button-font-size:15px;
  --button-transition:200ms all ease-in;

}
body {
  background: #fafafa;
}
.journal-btns {
  display: flex;
  justify-content: space-between;
}
.btn {
  box-shadow: 0px 1px 4px 0px #00000040;
  border-radius: 5px;
  margin-bottom: 20px;
}
.journal-first {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-top: 20px;
}
.journal-left-input input {
  border: 1px solid #c1c1c1;
  background-color: #fafafa;
  border-radius: 5px;
  width: 400px;
  height: 40px;
  padding: 10px 20px 10px 20px;
}
.journal-right-input textarea {
  border: 1px solid #c1c1c1;
  background-color: #fafafa;
  border-radius: 5px;
  padding: 20px;
  width: 550px;
  height: 112px;
}
.journal-main-container {
  position: relative;
  width: 100%;
  height: 257px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #00000040;
}
.journal-functions-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.journal-functions-left img {
  width: 26px;
  height: 26px;
}

.journal-functions-right {
  position: relative;
  display: flex;
  align-items: center;
}
.journal-functions-right input {
  background-color: #f8f8f8;
  padding: 10px 20px 10px 25px;
  border-radius: 5px;
  margin-right: 5px;
}
.journal-functions-right input::placeholder {
  color: #3f3f3f;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  font-size: 11px;
}
.journal-functions-right img {
  position: absolute;
  left: 5px;
  width: 15px;
  height: 15px;
}
.journal-print-module {
  border: 1px solid;
  position: fixed;
  width: 720px;
  height: 245px;
  padding: 10px;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
  border-radius: 11px;
  z-index: 999;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.journal-print-module h3 {
  font-family: system-ui, Roboto;
  color: #343434;
}
.journal-print-module-content {
  height: 130px;
  width: 685px;
  background-color: #f0f2f4;
  border-radius: 10px;
  padding: 10px;
}
.journal-print-module-first {
  display: flex;
  gap: 20px;
  align-items: center;
}
.journal-print-module-first label {
  font-weight: 400;
}
.journal-print-module-first input {
  border: 1px solid #bebebe;
  background-color: #ffffff;
  width: 200px;
  height: 32px;
  border-radius: 5px;
  opacity: 60%;
  padding: 10px 20px 10px 20px;
}
.journal-print-module-second {
  display: flex;
  gap: 32px;
  margin-top: 10px;
  align-items: center;
}
.journal-print-module-count-container {
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 2px;
}
.journal-print-module-count-buttons-container {
  display: flex;
  width: 26px;
  height: 32px;
  flex-direction: column;
  background-color: #1f5ea7;
  border-radius: 5px;
}
.journal-print-module-count-container span {
  border: 1px solid #bebebe;
  background-color: #eeeeee;
  padding: 5px 9px 5px 9px;
  border-radius: 5px;
}
.journal-print-module-buttons {
  display: flex;
  position: relative;
  left: 350px;
  top: 10px;
  gap: 10px;
}
.journal-print-module-buttons button {
  border: 1px solid #1f5ea7;
  border-radius: var(--button-radius);
  padding: var(--button-padding);
  font-family: roboto;
  color: var(--button-background);
  font-size: var(--button-font-size);
  font-weight: 500;
}
.journal-print-module-buttons button:hover {
  background-color: var(--button-background);
  border: none;
  color: white;
  font-weight: 300;
  transition: var(--button-transition)
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 997;
}
.journal-main-content-services-container {
  border: 1px solid;
  position: fixed;
  width: 720px;
  height: 245px;
  padding: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 11px;
  z-index: 999;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.journal-services-select select {
  appearance: none;
  width: 100%;
  font-size: 1.15rem;
  padding: 0.675em 6em 0.675em 1em;
  background-color: #f0f0f0;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: #000;
  cursor: pointer;
}
.journal-services-select option:focus {
  border-radius: 5px;
  background-color: #e0a761;
}
.journal-main-content-services-content-first {
  display: flex;
  gap: 230px;
  margin-left: 90px;
}
.journal-main-content-services-content-second {
  display: flex;
  justify-content: space-around;
}
.journal-main-content-services-content-second select {
  border: none;
}

.journal-main-content-services-content-second input {
  border: 1px solid #bebebe;
  background-color: #ffffff;
  width: 200px;
  height: 32px;
  border-radius: 5px;
  opacity: 60%;
  padding: 10px 20px 10px 20px;
}
.journal-main-container-buttons{
  display: flex;
  position: relative;
  left: 350px;
  top: 10px;
  gap: 10px;

}
.journal-main-container-buttons button{
  border: 1px solid #1f5ea7;
  color: var(--button-background);
  border-radius: var(--button-radius);
  font-size: var(--button-font-size);
  padding: var(--button-padding);
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
.journal-main-container-buttons button:hover{
  background-color: var(--button-background);
  transition: var(--button-transition);
  color: white;

}
