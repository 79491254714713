.permission-cont {
    display: flex;
    height: 100%;
    background-color: #f9f9f9;
  }
  
  .permission-list-container {
    flex: 1; /* Take remaining space */
    padding: 20px;
    overflow-y: auto;
  }
  
  .permission-list2 {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Add space between groups */
  }
  
  .permission-group {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .permission-group-title {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
  }
  
  .permission-items {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .permission-item {
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .permission-item:hover {
    background-color: #f8f8f8;
  }
  
  .permission-checkbox-label {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .permission-checkbox {
    accent-color: #ff7e47;
    cursor: pointer;
  }
  
  .permission-item-text {
    font-size: 14px;
    font-weight: 500;
    color: #555;
  }
  
  /* User permission ui */
  .custom-checkbox {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    user-select: none;
  }
  
  .custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 3px;
  }
  
  .custom-checkbox:hover input ~ .checkmark {
    background-color: #ddd;
  }
  
  .custom-checkbox input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .custom-checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  
  .custom-checkbox .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  
  /* Submit button alignment */
  .submit-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .btn btn-primary {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn btn-primary {
    background-color: #0056b3;
  }
  