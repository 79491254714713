/*
 .admin-page-container {
  background: #154674;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.admin-form-container {
  width: 30%;
  border-radius: 0.5rem;
  background: #f4f4f4;
  padding: 2rem;
  height: auto;
}

.admin-title-label {
  color: #5d5d5d;
  font-size: 1.375rem;
  font-weight: 600;
  padding: 0rem 0rem;
  text-align: center;
}

.admin-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.input-label-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 2px;
}

.admin-input {
  border: 1.5px solid gray;
  border-radius: 7px;
  width: 200px;
}

.card {
  height: 30%;
}

.card .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 576px) {
  .admin-form-container {
    width: 80%;
    height: 75%;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .admin-form-container {
    width: 70%;
    height: auto; 
  }
} */

.admin-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.card {
  margin: 100px auto;
}
