.buttonviewdown {
  width: 6rem;
  height: 2.6rem;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  color: #1f5ea7;
  border: 2px solid #1f5ea7;
  font-size: medium;
  font-weight: 400;
  margin-right: 0.5rem;
}

.buttonviewdown:hover {
  color: #fafafa;
  background-color: #1f5ea7;
  border: 2px solid #1f5ea7;
}

.bed-card {
  position: relative;
}

.selected-bed {
  border: 2px solid blue;
}

.available-bed {
  background-color: #f6fff6;
  border: 2px solid #35f983;
  color: #35f983;
}

.reserved-bed {
  background-color: #fffbed;
  border: 2px solid #ffe071;
  color: #ffe071;
}

.occupied-bed {
  background-color: #fffbf8;
  border: 2px solid #ffb26b;
  color: #ffb26b;
}

.move-icon {
  position: absolute;
  top: -23px;
  left: -23px;
}

.primary-bg {
  color: #e3b075;
  background-color: #fafafa;
  border: 2px solid #e3b075;
}

.primary-bg.active {
  color: #3f3f3f;
  background-color: #e3b075;
  border: 2px solid #e3b075;
}

/* Styles for DischargeBasicDetails page */
.tab-navigation {
  display: flex;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #f8f8f8;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  margin: 2px;
}

.tab-button:hover {
  background-color: #e3b075;
}

.active-tab {
  background-color: #e3b075;
}
