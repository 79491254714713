.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.left-side {
    background-color: #fcd7b6;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 100%;
}
.login-head{
    font-size: xx-large;
    font-weight: bolder;
}
.form-input{
    font-size: larger;
    border: 2px solid; 
    background: #E7E7E7;
    border-radius: 15px !important;
}
.login-button {
    background-color: #7c0000;
    color: white;
    border: 2px solid white;
    width: 75%;
    font-size:x-large;
    border-radius: 15px !important;
}
.login-button:hover {
    background-color: white;
    color: #7c0000;
    border: 2px solid #7c0000;
}