/* General Styling */
body {
    font-family: Arial, sans-serif;
    background-color: #f8f9fa; 
    color: #333;
    margin: 0;
    padding: 0;
}

.top-bar-accountList{
    padding: 15px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

/* Main Container for Form and Table */
.item_add {
    max-width: 1500px;
    margin: 1rem auto;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    gap: 2rem;
    padding: 2rem;
    flex-wrap: wrap;
}

.item_add_cont{
    padding: 0 20px;
}

/* Form Container */
.item_add_form_part1 {
    flex: 1; 
    padding-right: 1rem;
    /* border-right: 1px solid gray; */
}

/* Table Container */
.item_add_form_part2 {
    flex: 1;
    overflow-x: auto;
}

/* Labels and Inputs */
.d-flex {
    align-items: center;
    margin-bottom: 1rem;
}

.item_input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    background-color: #EEEEEE;
    border-radius: 4px;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s ease;
}

.item_input:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

/* Dropdown Styling */
form .form-select {
    /* padding: 0.5rem; */
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #545D6E;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    width: 7rem !important;
}

/* Table Styling */
.account-group-table th,
.account-group-table td {
    text-align: left;
    padding: 0.5rem;
    font-size: 1rem;
    background-color: #fff;
}

.account-group-table input,
.account-group-table select {
    width: 100%;
    padding: 0.3rem;
    font-size: 0.9rem;
    /* border: 1px solid #ccc; */
    background-color: #EEEEEE;
    border-radius: 4px;
}

/* Buttons */
.add_btn {
    cursor: pointer;
    padding: 0.5rem !important;
    border-radius: 4px;
    font-size: 1rem;
    background-color: #545D6E;
    color: #fff;
    text-align: center;
    margin-left: 2rem;
}

button.add-btn {
    background-color: #154674;
    color: #fff;
}

button.add-btn:hover {
    background-color: #0b4173;
}

button.clear-btn {
    background-color: #fff;
    color: #154674;
}

button.clear-btn:hover {
    background-color: #154674;
    color: #fff;
}

/* Checkbox Styling */
.account-select-black-accent {
    width: 16px;
    height: 16px;
    margin-left: 0.5rem;
}

/* Table Container Styling */
.account-table-container {
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #EEEEEE;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .item_add {
        flex-direction: column;
    }

    .item_add_form_part1 {
        border-right: none;
        padding-right: 0;
    }
}
