.staff-attendance {
    border: 1px solid rgb(12, 12, 12);
    opacity: 0px;
    box-shadow: 5px;
    border: 1px solid white;
    box-shadow: 0px 0px 6px rgba(60, 59, 59, 0.1);
    background-color: #fff;
    border-radius: 8px;
  }

  /* .staff-attendance-main{
    background-color: #209FD6;
  } */
  

  .staff-attendance-head-row{
    border: 1px solid rgb(12, 12, 12);
    opacity: 0px;
    box-shadow: 5px;
    border: 1px solid white;
    box-shadow: 0px 0px 1px 1px rgba(181, 178, 178, 0.1); 
    background-color: #fff;
    border-radius: 8px;
  }

  .staff-attendance-main{
    border: 1px solid rgb(12, 12, 12);
    opacity: 0px;
    box-shadow: 5px;
    border: 1px solid white;
    box-shadow: 0px 0px 1px 1px rgba(181, 178, 178, 0.1); 
    background-color: #fff;
    border-radius: 8px;
  }

  .modal-content{
    max-height: 500px;
    overflow-y: auto;
  }

  /* .custom-table .custom-table-head-tr{
    padding: 15px; 
    text-align: left;
  } */
   /* .custom-table-head tr{
    padding: 20px;
   } */
    
  .custom-table-ducation-head tr th{
    width: 20%;
    padding: 5px;
    color: rgb(114, 111, 111);
  }
  .custom-table-ducation-body tr td{
    padding: 5px;
    /* border-radius: 8px;  */
  }
  .custom-table-ducation-body tr{
    border-radius: 8px;
    margin-bottom: 5px; 
  }

  .custom-table-head tr th{
    width: 20%;
    padding: 5px;
    color: rgb(114, 111, 111);
  }
  .custom-table-body tr td{
    padding: 5px;
    /* border-radius: 8px;  */
  }
  .custom-table-body tr{
    border-radius: 8px;
    margin-bottom: 5px; 
  }
  .profile-id{
    color: #209FD6;
  }
  .profile-inside-name{
    font-family: Roboto;
  }
  .gender{
    font-size: 12px;
    font-family: Roboto;
    padding: 2px;
    background: #EFEFEF;
    border-radius: 5px;
    cursor: pointer;
  }
  .select-gender{
    /* background: #154021; */
    background-color: #154021;
    color: white;
  }
  /* .gender div:first-child{
    background: #154021;
    color: white;
  } */
  /* .gender{
    display: block;
    margin-top: 0.5rem;
  } */

  /* background: #EEEEEE; */

  /* background: #209FD6; */

  /* Remove the calendar icon */
  .date-input::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }

.date-input:focus {
  outline: none;
  border-color: #0056b3;
}


