.hrms-report-container{
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding: 20px;
}
.report-buttons{
    position: relative;
    min-width: 765px;
    bottom: 25px;
    display: flex;
    gap: 10px;
}
.report-btn{
    border-top: 1px solid #f1f1f1;
    border-left: 1px solid #E7E6E5;
    border-right: 1px solid #E7E6E5;
    border-bottom: 1px solid #E7E6E5;
    box-shadow: 0px 2px 3px #E7E6E5;
    border-radius: 5px;
    padding: 8px 16px;
    font-size: 13px;
    margin: 5px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}
.report-btn:hover{
    transition: 300ms all ease-in-out;
    background-color: #E7E6E5;
}
.report-btn:focus{
    background-color: #E7E6E5;
    transform: translateY(3px);
}
.hrms-sideheader{
    position: absolute;
    top: 50px;
}
.date-picker-container{
    display: flex;
    justify-content: left;
    margin-bottom: 20px;
    gap: 10px;
}
.date-picker-container label{
    font-size: 14px;
    font-weight: 300;
    padding-top: 5px;
    padding-right: 20px;
}
.date-input{
    margin-right: 50px;
    display: flex;
}
.date-input input{
    width: 250px;
    height: 32px;
    padding-left: 10px;
    padding-right: 8px;
    background-color: #EEEEEE;
    border-radius: 4px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url(https://i.ibb.co/mRGXtCj/Calendar.png) no-repeat;
    width: 15px;
    height: 15px;
    border-width: thin;
}
.end-buttons{
    display: flex;
    position: relative;
    left: 750px;
    gap: 20px;
    margin-top: 20px;
}
.end-buttons button{
    border: #154674 2px solid;
    background: none;
    color: darkblue;
    width: 120px;
    height: 40px;
    border-radius: 14.39px;
}
.end-buttons button:hover{
    color: white;
    background-color: #154674;
    transition: .6s all ease;
}