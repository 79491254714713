.container-fluid {
    padding: 15px; /* Ensure proper padding inside the container */
  }
  
  .bg-white {
    background-color: #fff;
  }
  
  .p-3 {
    padding: 1rem; /* Adds padding for better spacing */
  }
  
  .shadow {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds subtle shadow */
  }
  
  .rounded {
    border-radius: 5px; /* Adds rounded corners */
  }
  