.table-header {
  background-color: #fbd9b3;
  font-weight: bold
}

.invoice-details {
  border: 1px solid #eaeced;
  background-color: #f8f9fa;
  border-radius: 0.4rem;
  /* height: auto; */
  width: 95%;
  padding: 1.5rem;
  /* margin: 1rem; */
  }

.table-body {
  background-color: #F0F2F4;
  height: 40vh;
}

.summary {
  position: absolute;
  bottom: 0;
  right: 5px;

}

.sub-total {
  background-color: #E3B075;
  font-weight: bold
}

.row-content {
  border-bottom: 0.1rem solid #A1A1A1;
  vertical-align: middle;
}
