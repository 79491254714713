.payroll-report-table {
  width: 100%;
  table-layout: fixed;
}

.table-payroll-report-head th {
  background-color: #f8f9fa;
  padding: 0.8rem;
  text-align: left;
  font-size: 14px;
}

.payroll-table-body td {
  padding: 0.8rem;
  font-size: 14px;
  /* border: 1px solid #ddd; */
}

.input-container input {
  width: 100%;
  padding: 0.5rem;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  font-size: 14px;
  background-color: #f9f9f9;
}

.input-container input:focus {
  outline: none;
  border-color: #4A00A8;
}

.payroll-table-body tr:hover {
  background-color: #f0f0f0;
}

tfoot td {
  padding: 1rem;
  font-weight: bold;
  font-size: 16px;
}
