.img-container{
    width: 100px;
    aspect-ratio: 1/1;
}

.text-sub{
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400; 
}

.text-main{   
    font-size: 1.4375rem;
    font-style: normal;
    font-weight: bold; 
}

.text-num{   
    font-size: 1.8rem;
    font-style: normal;
    font-weight: bold; 
}

.img-container1{
    width: 25px;
    aspect-ratio: 1/1;
}