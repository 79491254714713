/* Styling for consultation report page */
.patient-report-container{
  background-color: #f8f8f8;
  padding: 1.5rem;
  border-radius: 10px;
}
/* Styles for MedicineReports page */
.medicine-table table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}

.medicine-table th,
.medicine-table td {
  /* border: 1px solid #ddd; */
  padding: 8px;
  text-align: center;
}

.medicine-table thead th {
  background-color: #f8d7a7;
  color: black;
}

.table-header td {
  background-color: #fef4e6;
  font-weight: bold;
  text-align: left;
  padding: 8px;
}

.medicine-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.medicine-table tr:hover {
  background-color: #f1f1f1;
}
