/* Container */
.add-service-container {
    width: 800px;
    margin: 50px auto;
    background: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: Arial, sans-serif;
    padding: 20px;
}

.add-service-header {
    background: #d69c5c; /* Top header color */
    padding: 10px;
    border-radius: 8px 8px 0 0;
    color: #fff;
    text-align: left;
}

.add-service-header h2 {
    margin: 0;
    font-size: 1.2rem;
}

/* Form Layout */
.add-service-form {
    padding: 20px 15px;
}

.form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.form-column {
    width: 48%;
}

.form-column label {
    display: block;
    font-size: 0.9rem;
    color: #333;
    margin-bottom: 5px;
}

.form-column input,
.form-column select {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

/* Tax Section */
.tax-section {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    margin-bottom: 20px;
}

.tax-option {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.9rem;
    color: #333;
}

/* CGST Section */
.cgst-section {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
}

.cgst-section .form-column {
    width: 32%;
}

.button-group {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
}

.cancel-btn {
    background: #f1f1f1;
    border: none;
    color: #333;
    padding: 8px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.save-btn {
    background: #003366;
    color: #fff;
    border: none;
    padding: 8px 20px;
    border-radius: 4px;
    cursor: pointer;
}

/* modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99; 
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .close-btn {
    position: absolute;
    top: 20px;
    right: 30px;
    background-color: rgb(142, 47, 47);
    color: white;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .add-service-form {
    display: flex;
    flex-direction: column;
  }
  
  .add-service-form input,
  .add-service-form select {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
  }
  
  .add-service-form button {
    padding: 10px;
    background-color: #1F5EA7;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  .add-service-form button:hover {
    background-color: #155a80;
  }
  