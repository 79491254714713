  .accordion {
      margin: 6px;
    }
  
  .accordion-header {
    padding: 10px;
    cursor: pointer;
    border-radius: 0.3125rem;
    background-color: #e3b075;
    color: #343434;
    display:flex;
  }
  
  .accordion-content {
    padding: 10px;
    border-radius: 0.3125rem;
    background-color: #EDEDED;
    color: black;
    display: none;
    height: 15rem;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  
  .accordion.open .accordion-content {
    display: block;
  }
  
  .accordion-main{
    display:flex;
    background-color: #343434;
    color: #ffffff;
    width: 7.8125rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125rem;
    margin: auto;
  }

  .accordion-sub{
    display:flex;
    height: 2.5rem;
    background-color: #FFB1B1;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125rem;
    margin: auto;
  }

  .assessment-add-btn{
    background: #343434;
    border-radius: 0.3125rem;
    padding: .75rem 1rem .75rem 1rem;
    color: #FFF;
    font-size: 0.8125rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .assessment-main{
    width: 6rem;
    background: #e3b075;
    border-radius: 0.3125rem;
    padding: .75rem 1rem !important;    
    color: #FFF;
    font-size: 0.8125rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
  }

  .assessment-sub{
    border-radius: 0.3125rem;
    padding: .75rem 0.5rem .75rem 0.5rem !important;
    display: flex;
    align-items: center;
    font-size: small;
  }

  .assessment-sub.pink{
    background: #FFB1B1;
  }

  .assessment-sub.blue{
    background: #A1D7FF;
  }

  .assessment-desc{
    background: #FFF;
    border-radius: 0.3125rem;
    padding: .75rem 1rem .75rem 1rem !important;
    display: flex;
    justify-content: space-between;
    font-size: small;
  }

  .close{
    color: #303030;
    font-size: 1.1rem;
    transform: rotate(-45deg);
    cursor: pointer;
  }