.payment-section label {
    font-weight: normal;
}


.e-payment-sub {
    background-color: #FFF;
    padding: 0.6rem;
    border-radius: 0.3rem;
}

.input-group {
    display: flex;   
    justify-content: space-between;
    margin: 0.4rem 0;
    gap: 0.7rem;
}

.check-group {
    display: flex;   
    /* justify-content: space-between; */
    margin: 0.4rem 0;
}

.payment-input {
    border: 1px solid #eaeced;
    border-radius: 0.3rem;
    padding: .3rem;
    background-color: #f8f9fa;
}
 
.summary-section {
    border: 1px solid #eaeced;
    background-color: #f8f9fa;
    border-radius: 0.3rem;
    height: auto;
    width: 95%;
    padding: 1.8rem;
    margin: 1rem;
    margin-right: 1.5rem;
}

.summary-section label, .summary-section .input-group {
    font-size: 1.1rem;
    font-weight: bold;
}
