.white-plain-container{
    min-height: 10rem;
    border-radius: 0.3125rem;
    background: #FFF;
    box-shadow: 0px 0px 6px 0px rgba(104, 104, 104, 0.25);
}

.make-appointment-id{
    color: #108BE4;
    font-size: 1.0625rem;
    font-weight: 600;
    line-height: normal;
}

.make-appointment-name{
    color: #212121;
    font-size: 1.375rem;
    font-weight: 600;
    line-height: normal;
}

.make-appointment-details{
    color: #929292;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
}

.make-appointment-details .label{
    width: 27%;
    flex: 0 0 auto;
}

.make-appointment-details .colon{
    width: 1%;
    flex: 0 0 auto;
    color: #323232;
}

.make-appointment-details .data{
    padding-left: 1rem;
    width: 72%;
    flex: 0 0 auto;
    color: #323232;
}

.search-arrow-btn{ 
    width: 4.375rem;
    height: 2.09863rem;
    margin-left: 0.31rem;
    border-radius: 0.3125rem;
    background-color: var(--baseColor);
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.search-arrow-btn:hover{
    background-color: #28344d;
}