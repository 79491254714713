/* General Styles */
.service-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  button {
    cursor: pointer;
  }

  .top-bar-addAccount{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  
  /* Top Bar */
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .filter-sorting {
    display: flex;
    gap: 10px;
  }
  
  .filter-btn,
  .sort-btn {
    background-color: white;
    color: #000;
    border: solid gray 1px;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .filter-btn:hover,
  .sort-btn:hover {
    background-color: white;
  }
  
  .search-bar input {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 250px;
  }
  
  .pagination {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .nav-btn {
    background-color: #d39d60;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .nav-btn:hover {
    background-color: #eab58d;
  }
  
  /* Table */
  .service-table {
    width: 100%;
    border-collapse: separate; 
    border-spacing: 0 6px;
  }
  
  .service-table thead th {
    padding: 10px;
    background-color: #fbd9b3; /* Header Color */
    color: #000;
    text-align: left;
  }
  
 /* Table Header: Curved Corners */
.service-table thead th:first-child,
.service-table tbody tr td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.service-table thead th:last-child,
.service-table tbody tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

  
  .service-table tbody tr {
    border-radius: 8px;
    overflow: hidden;
  }
  
  .service-table td {
    padding: 10px;
    text-align: left;
    background-color: #fffaF4;
  }
  
  .service-table tr td:first-child {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }
  
  .service-table tr td:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .service-table tr:hover td {
    background-color: #f5eae0;
  }
  
  .service-name {
    color: #e68a54;
  }