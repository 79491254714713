/* Basic form styles start */
.form-check-input:focus {
    border-color: #154674;
    box-shadow: 0 0 0 0.2rem rgba(40, 95, 167, 0.25);
}

.form-control:focus {
    border-color: #154674;
    box-shadow: 0 0 0 0.1rem rgba(40, 95, 167, 0.25) inset, 0 0 0 0.2rem rgba(40, 95, 167, 0.25);
    /* box-shadow: 0 1px 1px rgba(40, 95, 167, 0.25) inset, 0 0 8px rgba(40, 95, 167, 0.25); */
}

.form-control:disabled{
    background: #fff;
}

.input-check-control{
    display: flex;
    align-items: center;
}

.input-check-control .form-check-input {
    width: 1rem !important;
    height: 1rem !important;
    margin-top: 0rem !important;
}

.input-check-control .form-check-input:checked {
    background-color: #154674;
    border-color: #154674;
}

.input-check-control .form-check-label {
    color: #343434;
    font-size: 0.83944rem;
    margin-left: 0.4rem;
    font-weight: 500;
    line-height: normal;
}

.label-text-content {
    color: #343434;
    font-size: 0.83944rem;
    font-weight: 500;
    line-height: normal;
}

.form-control {
    font-size: 11px;
    cursor: text;
}

/* Basic form styles end */