.patient-list-container {
  padding: 0;
  /* border-radius: 0.3125rem;
    background: #FFF; */
  /* margin: 1rem 1rem 1rem 1rem; */
}

.patient-list-table {
  border-collapse: separate;
  border-spacing: 0 0.3rem;
  background-color: transparent;
}

.patient-list-table-head tr th:first-child{
  border-top-left-radius: 10px;
  border-bottom-left-radius:10px;
}

.patient-list-table-head tr th:last-child{
  border-top-right-radius: 10px;
  border-bottom-right-radius:10px;
}


.patient-list-table-head tr th {
  background-color: #FBD9B3;
  font-weight: bolder;
  font-size: 14px;
  padding: 1rem 1rem 1rem 1rem;
}


.patient-list-table-body tr td {
  background-color: #FFFAF4;
  font-weight: 400;
  font-size: 15px;
  padding: 1rem 1rem 1rem 1rem;
}
.patient-list-table-body tr td:first-child,
.patient-list-table-body tr th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.patient-list-table-body tr td:last-child,
.patient-list-table-body tr th:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

/* .patient .card {
  margin: none;
} */

/* .patient-modal {
  overflow: scroll;
  height: 100%;
  width: 100%;
} */


.edit-patient{
  width: 2.25rem;
  height: 2.25rem;
  /* margin: 0.05rem 0rem; */
  border-radius: 0.3125rem;
  background-color: #0A2278;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.edit-patient:hover{
  color: #0A2278;
  background-color: transparent;
  border: 2px solid #0A2278;
}

.delete-patient {
  width: 2.25rem;
  height: 2.25rem;
  margin: 0.05rem 0rem;
  border-radius: 0.3125rem;
  background-color: #DE7C7C;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.delete-patient:hover{
  color: #DE7C7C;
  background-color: transparent;
  border: 2px solid #DE7C7C;
}

.patienttextdata:hover{
  color: #6028FF;
  text-decoration: underline !important;
  cursor: pointer;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20rem; /* Adjust height as needed */
}