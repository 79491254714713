/* TherapyForm.css */

.form-container {
  max-width: 900px;
  margin: 20px auto;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
}

.form-header {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.form-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.tax-section {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.tax-field {
  flex: 1;
}

.flex-section {
  display: flex;
  gap: 32px;
  margin-bottom: 16px;
}

.therapy-room,
.therapists {
  background-color: #efefef;
  border-radius: 4px;
  padding: 25px;
}

.therapy-room {
  margin: 0 15px;
}

.form-check-label {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: white;
  width: 100%;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 5px;
}

.section-header {
  margin-bottom: 8px;
  font-weight: bold;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.checkbox-group input {
  margin-right: 8px;
}

.search-input {
  width: 15rem;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 8px;
}

.therapists-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.submit-button {
  padding: 8px 35px;
  background-color: #e3b075;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.submit-button:hover {
  background: #febc70;
}
