/* .audio-recorder {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  } */
  
  .recorder-container {
    display: flex;
    justify-content:space-evenly ;
    align-items: center;
    border-radius: 30px;
    background-color: #986949;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .progress-bar {
    flex-grow: 1;
    height: 8px;
    background-color: #f0f0f0;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 15px;
  }
  
  .progress {
    height: 100%;
    background-color: #E3B075;
    transition: width 1s linear;
  }

  #waveform {
    width: 100%; 
    height: 34px; 
    border-radius: 8px;
    background-color: #f0f0f0; 
  }
  
  .audio-controls button {
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .audio-controls button:hover {
    background-color: #2980b9;
  }
  