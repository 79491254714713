.popup-btn {
  background-color: #eeeeee;
  padding: 0.5rem;
  text-align: center;
  cursor: pointer;
  color: black;
  border: 3px solid #fafafa;
}

.activebtn {
  background-color: #e3b075;
}

.adm-popup-height {
  height: 46rem;
  padding: 0.625rem;
}

.form-control {
  background-color: rgb(244, 244, 244) !important;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.billing-details-container {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  margin-left: 0px;
}

.label-text-content {
  font-size: 0.875rem;
  font-weight: 500;
  color: #555;
  display: block;
  margin-bottom: 0.5rem;
}

.previous-details-container {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  margin-top: 15px;
}

.table-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.details-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;
  text-align: left;
}

.details-table thead tr {
  background: #f9d7b3;
  border-radius: 5px;
}

.details-table th {
  padding: 0.75rem;
  font-weight: 600;
  color: #000;
}

.details-table tbody tr:nth-child(even) {
  background: #f4f4f4;
}

.details-table tbody tr:nth-child(odd) {
  background: #ffffff;
}

.details-table td {
  padding: 0.75rem;
  color: #555;
}

.details-table td:nth-child(2) {
  color: #1a73e8; /* Blue color for dates */
}
