/* Define Variables */
:root {
    --primary-color: #E3B075;
    --secondary-color: #fbd9b3;
    --accent-color: #e68a54;
    --hover-color: #f5eae0;
    --background-color: #fffaF4;
    --button-background: #d39d60;
    --button-hover-background: #eab58d;
    --border-color: #ddd;
    --font-family: Arial, sans-serif;
    --padding: 10px;
    --border-radius: 5px;
    --input-padding: 8px 12px;
    --button-padding: 8px 12px;
    --table-header-padding: 10px;
}

/* General Styles */
.common-container {
    padding: 20px;
    font-family: var(--font-family);
}

button {
    cursor: pointer;
}

/* Top Bar */
.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.filter-sorting {
    display: flex;
    gap: 10px;
}

.filter-btn,
.sort-btn {
    background-color: white;
    color: #000;
    border: solid var(--border-color) 1px;
    padding: var(--button-padding);
    border-radius: 4px;
    cursor: pointer;
}

.filter-btn:hover,
.sort-btn:hover {
    background-color: var(--primary-color);
}

.search-bar input {
    padding: var(--input-padding);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    width: 250px;
}

/* Pagination */
.pagination {
    display: flex;
    align-items: center;
    gap: 10px;
}

.nav-btn {
    background-color: var(--button-background);
    border: none;
    padding: var(--button-padding);
    border-radius: 4px;
    cursor: pointer;
}

.nav-btn:hover {
    background-color: var(--button-hover-background);
}

/* Table */
.all-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 1rem !important;
    table-layout: fixed;  
}

.all-table thead th {
    padding: var(--table-header-padding);
    background-color: var(--secondary-color);   
    color: #232323;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 14px;
}

/* Table Header: Curved Corners */
.all-table thead th:first-child,
.all-table tbody tr td:first-child {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
}

.all-table thead th:last-child,
.all-table tbody tr td:last-child {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
    
}

.all-table tbody tr {
    border-radius: var(--border-radius) !important;
    overflow: hidden !important;
}

.all-table td {
    padding: var(--padding);
    text-align: left;
    background-color: var(--background-color);
}

.all-table tr td:first-child {
    border-bottom-left-radius: var(--border-radius) !important;
    border-top-left-radius: var(--border-radius) !important;
}

.all-table tr td:last-child {
    border-bottom-right-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
}

.all-table tr:hover td {
    background-color: var(--hover-color);
}

/* Empty Table State */
.all-table tbody tr td {
    /* text-align: center; */
    padding: 20px;
}

/* common Name Style */
.common-name {
    color: var(--accent-color);
}

/* Responsive Design */
@media (max-width: 768px) {
    .all-table {
        font-size: 14px;
    }

    .all-table td,
    .all-table th {
        padding: 8px;
    }
}
