@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;600;700&display=swap');

/* .card-preview-container{} */

.card-front-preview{
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-back-preview{
    display: flex;
    align-items: center;
    justify-content: center;
}

.patient-card-container{
    font-family: 'Roboto Mono';
    background-repeat: no-repeat;
    background-size:cover;
    height: 53.98mm;
    width: 85.6mm;
    border-radius: 3.18mm;
    /* border: 1px solid #000; */
}

.card-front-preview .patient-card-container{
    background: linear-gradient(to bottom,#000000, #3a3a3a), url(../../../../../assets/pictures/images/diagonal-bg.jpg);
    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-position: top right, bottom center;
    background-size:200%, 150%;
}

.card-back-preview .patient-card-container{
    background: linear-gradient(to top,#000000, #3a3a3a), url(../../../../../assets/pictures/images/diagonal-bg.jpg);
    background-blend-mode: overlay;
    background-position: bottom left, bottom center;
    background-size:200%, 150%;
}

.patient-card-header{
    font-size: 0.45cm;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #000;
}

.patient-card-header .hospital-img-logo{
    width: 1.5cm;
    height: 1.2cm;
}

.patient-card-header .hospital-text-logo{
    /* width: ; */
    height: 0.6cm;
}

.card-contact-info{
    font-size: 0.25cm;
}

.patient-card-id{
    font-family: 'Roboto Mono';
    color: #000;
    display: flex;
    justify-content: center;
    font-size: 0.7cm;
    /* font-style: italic; */
    font-weight: 500;
}

.patient-card-label{
    color: #686868;
    font-size: 0.32cm;
    font-weight: 500;
}

.patient-card-label.sub{
    font-family: 'Roboto';
    font-size: 0.3cm;
}

.patient-card-value{
    color: #cda700;
    font-size: 0.4;
    font-weight: 600;
}

.card-end-data{
    display: flex-end;
    flex-direction: column;
}

.card-barcode-container{
    display: flex;
    justify-content: center;
}

.card-back-title{
    display: flex;
    justify-content: center;
    font-weight: 600;
    color: #000;
}

.card-point-container{
    color: #000000;
    height: 2.7cm;
    line-height: 0.32cm;
}

.card-back-point{
    font-size: 0.3cm;
}

.generate-card-btn{
    width: fit-content;
    height: 2.5183rem;
    padding: 1rem 2rem 1rem 2rem;
    flex-shrink: 0;
    border-radius: 0.3125rem;
    border: 2px solid #154674;
    background-color: #154674;
    color: #FFFFFF;
    font-size: 0.83944rem;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.generate-card-btn:hover{
    color: #154674;
    background-color: #FFFFFF;
}

.icon-input-box::placeholder{
    text-align: start;
}

.cardSearch-popup-container{
    margin-left: -7.5rem;
    margin-top: -0.5rem;
    background: #FFF;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 0.5rem;
    width: auto;
    overflow: hidden;
}

.search-option-data{
    background: #F0F0F0;
    width: 17rem;
    height: 1.5625rem;
    padding: 0rem 0.2rem 0rem 0.2rem;
    margin-bottom: 0.19rem;
    display: flex;
    align-items: center;
    border-radius: 0.3125rem;
}

.search-icon-btn{
    width: 2.09863rem;
    height: 2.09863rem;
    margin-left: 0.31rem;
    background: #3A3A3A;
    border-radius: 0.3125rem !important;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.search-icon-btn:hover{
    background: #3A3A3A;
}