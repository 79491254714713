.report-container{
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 2rem !important;
}
.report-tab-navigation {
  display: flex;
  padding: 10px;
  margin-bottom: 20px;
}

.report-tab-button {
  border-bottom: 1px solid #ccc;
  padding: 10px 20px;
  cursor: pointer;
  background-color: white;
  /* border-radius: 5px; */
  font-size: 16px;
  color: #333;
  margin: 0px;
}

.report-tab-button:hover {
  background-color: #e3b075;
}

.active-tab {
  border-bottom: 5px solid #e3b075;
}
