/* sidebar container */
.sidebar-container {
    background: var(--baseColor);
    box-shadow: 1px 0px 6px 0px rgba(0, 0, 0, 0.25);
    min-width: 15rem;
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100vh;
    border: 8px solid var(--baseColor);
    border-radius: 32px;
    z-index: 21;
    overflow-x: hidden;
}

/* scrollable container */
.link-container {
    overflow-y: auto; /* Allows vertical scrolling */
    overflow-x: hidden; /* Prevents horizontal scrolling */
    height: 100%;
    width: 250px;
    overflow-y: auto;
    padding-bottom: 1rem;
}

/* Hide scrollbar for Webkit browsers (Chrome, Safari, etc.) */
.sidebar-container::-webkit-scrollbar,
.link-container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for Firefox */
.link-container {
    scrollbar-width: none;
}

/* Styling for the links */
.sidebar-link {
    color: #343434 !important;
    align-items: center;
    justify-content: start;
    height: 40px;
    border-radius: 5px;
}

.sidebar-link.active {
    background: #E3B075;
}

/* sidebar css end */
