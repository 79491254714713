.main-content {
  flex: 1;
  padding: 15px;
  background-color: #ffffff;
}
.hrms-heading {
  position: absolute;
  left: 320px;
  top: 50px;
  z-index: 999;
}
.hrmsh-top-actions span {
  margin-left: 15px;
  cursor: pointer;
}

.profile {
  font-weight: bold;
}

.hrmsh-reports h1 {
  margin-bottom: 20px;
}
.hrmsh-reports h3 {
  margin-left: 10px;
}
.hrmsh-report-grid {
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, 1fr);
  gap: 3px;
  margin: 0;
  padding-left: 5px;
}
.hrmsh-report-container {
  position: relative;
}

.hrmsh-report-card {
  width: 500px;
  height: 53px;
  display: flex;
  flex-direction: row;
  border: 1px solid #e7e6e5;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  gap: 5px;
  background-color: #fffaf4;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-right: 0px;
  transition: 0.5s ease all;
  padding-left: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.hrmsh-report-card:hover {
  background-color: #f8e2c8;
  transition: 0.5s ease all;
  /* border: none; */
}
.hrmsh-report-card .icon {
  font-size: 30px;
  margin-bottom: 10px;
}
.hrmsh-top-bar {
  display: flex;
  position: absolute;
  align-items: center;
  top: 45px;
  right: 280px;
  z-index: 999;
}
.hrmsh-top-bar input::placeholder {
  /* color: #ccc; */
  /* display: none; */
  font-size: 11px;
}
.hrmsh-top-bar input {
  padding: 8px 0px 8px 40px;
  width: 150px;
  border: none;
  border-radius: 5px;
  cursor: text;
}
.FaMagnifyingGlass {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 14px;
}
.hrms-div1 {
  position: absolute;
  left: 0;
  width: 550px;
  z-index: 1000;
  overflow-y: visible;
  max-height: 400px;
}
.hrms-div2 {
  position: absolute; 
  display: grid;
  background: #FFFAF4;
  grid-template-columns: 200px 1fr;
  grid-template-areas: 
    "header right"
    "header right"
    "header right";
  border: 1px solid #e7e6e5;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.hrms-div1 img{
  margin-left: 20px;
}
.hrms-divh {
  grid-area: header;
  padding: 10px 0px 30px 10px;
  background-color: #F8E2C8;
  height: 49px;
  color: #3F3F3F;
  width: 159px;
  border-radius: 8px;
  font: roboto;
  font-weight: 600;
  font-size: 15px;
}
.hrms-divi {
  padding: 15px 15px;
  cursor: pointer;
  border-radius: 3px;
  background-color: #FFFAF4;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.hrms-divi:hover, .hrms-divi:focus {
  background-color: #f8e2c8;
  transform: scale(1.02); 
  outline: none; 
}
@media (max-width: 768px) {
  .hrmsh-report-grid {
    grid-template-columns: 1fr;
  }
}
.hrmsh-report-card:active {
  transform: scale(0.98);
}
@media (max-width: 768px) {
  .hrmsh-report-grid {
    grid-template-columns: 1fr;
  }

  .hrms-heading {
    left: 20px;
    top: 20px;
  }

  .hrms-div2 {
    width: 100%;
    box-shadow: none;
  }

  .hrms-div1 {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .hrms-div1 {
    width: 100%; /* Make dropdown take full width on smaller screens */
    max-height: 300px; /* Adjust height for mobile */
  }
}
@media (max-width: 768px) {
  .hrms-divi {
    padding: 10px 12px; /* Adjust padding for mobile */
  }
} 

