.Information-container div{
    font-size: 11px;
  }
  
  .Information-sub-one {
    flex: 1;
    background: #1cdb15;
  }
  
  .Information-sub-two {
    flex: 1;
    background: #db9c15;
  }
  
  .Information-sub-three {
    flex: 2;
    background: #2fdb15;
  }
  .Information-bottom p{
    font-size: 11px;
  }
  .duration{
    flex: 1;
  }
  .duration p:first-child{
    font-size: 12px;
    font-weight: bolder;
  }
  .admission p:first-child{
    font-size: 12px;
    font-weight: bolder;
  }
  .admission{
    flex: 3;
  }
  .Prakrity div:first-child{
    flex:1
  }
  .Prakrity div:nth-child(2){
    flex:2
  }
  .Prakrity div:last-child{
    flex:1
  }
  .color-faid{
    color: rgb(111, 107, 107);
  }
  .color-bright{
    color: rgb(67, 64, 64);
    font-weight: bolder;
  }
  ul li{
    font-size: 11px;
  }
.dis-button{
  background-color: #E3B075 ;
  font-size: 12px;
  width: 100px;
}
.ashtavidha-head{
  background-color: rgba(40, 38, 94, 0.655);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}
.bottom-head{
  font-size: 12px;

}

  

/* .Information-sub-one{
display: flex;
justify-content: space-evenly;
gap: 10px;
}

.Information-sub-one{
    flex: 1;
    background: #1cdb15;
}
.Information-sub-two{
    flex: 1;
    background: #db9c15;

}
.Information-sub-three{
    flex: 2;
    background: #2fdb15;

} */