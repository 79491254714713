.patient-container-fluid{
    border: 1px solid #ccc;
    border-radius: 10px;
}

.discharge-patientInfo-headline{
    background-color: #f8f8f8;
    padding: 1rem;
    border-radius: 5px;
}

.patientValue{
    font-weight: bold;
}