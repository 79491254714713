.settings-tabpane-container{
    position: fixed;
    background: #4C4C4C;
    padding: 0.81rem 0.81rem 0.81rem 0.81rem;
    display: flex;
    flex-direction: column !important;
    border-radius: 0.7125rem;
    height: 100vh;
    margin-right: 0rem;
}

.settings-tabpane-item{
    border-radius: 0.3125rem;
    padding: 0.63rem 1.44rem;
    margin-top: 0.63rem;
    height: 2.5rem;
    font-size: 0.875rem;
    display: flex-end;
    align-items: center;
    font-weight: 400;
    color: #FFF !important;
    background: #4C4C4C !important;
}

.settings-tabpane-item.active{
    color: #FFF !important;
    background: #7D7D7D !important;
}

.input-checkbox-control.select{
    background-color: #154674;
    color: #FFF;
}