/* .container {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
}

h5 {
    font-weight: bold;
    margin-bottom: 15px;
}

label {
    font-weight: 600;
}

.form-control[readonly] {
    background-color: #e9ecef;
    color: #495057;
    cursor: not-allowed;
}

.img-fluid {
    max-width: 100%;
    height: auto;
    border: 1px solid #ddd;
    padding: 5px;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 10px;
} */
.container {
  /* max-width: 1000px; */
  /* margin: 0 auto; */
  padding: 20px;
  /* background-color: #f9f9f9; */
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  height: 500px;
  overflow: scroll;
}

h5 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.form-control,
.form-select {
  margin-bottom: 10px;
}

label {
  font-weight: bold;
}

.mt-4 {
  margin-top: 1.5rem;
}

/*  */

.medical-records {
  margin: 20px;
}

.previous-medical-issues,
.treatment-history {
  margin-bottom: 20px;
}

/* Responsive Flexbox Layout */
.issue-tags {
  display: flex;
  flex-wrap: wrap; /* Wrap tags to the next line if necessary */
  gap: 10px;
}

.issue-tag {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  padding: 5px 10px;
  border-radius: 20px;
  position: relative;
  max-width: calc(100% - 30px); /* Ensure tags don't overflow */
}

.issue-tag.active {
  background-color: #d1e7dd; /* Green */
}

.issue-tag.inactive {
  background-color: #f8d7da; /* Red */
}

.remove-tag {
  margin-left: 8px;
  font-size: 12px;
  cursor: pointer;
}

.treatment-history {
  background-color: #f8f9fa;
  /* padding: 0; */
  border-radius: 10px;
}

.history-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.history-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background-color: #e9ecef;
  flex-wrap: wrap; /* Wrap content if needed on smaller screens */
}

.history-number {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #d1e7dd;
  border-radius: 50%;
  margin-right: 10px;
}

.history-text {
  font-size: 14px;
  color: #495057;
  flex: 1;
}
.op-div-first{
  background-color: #574957;
  background-color: #ccc;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.op-div-last{
  background-color: #574957;
  background-color: #ccc;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.profile-name{
  height: 4rem;
}
.profile-div{
  border-radius: 50%;
}

.tableStyle {
  width: 100%;
  border-collapse: separate;
  border-spacing: 5px 8px; /* Horizontal (column) and vertical (row) spacing */
}

.table-headerStyle {
  background-color: #FBD9B3;
  text-align: center;
  /* color: white; */
  border-radius: 5px;
  padding: 5px;
}
.table-cellStyle {
  padding: 5px;
  /* text-align: center; */
  background-color: #f2f2f2;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.first-column {
  width: 5%; 
}
.last-column {
  width: 8%; 
}
.second-third-column {
  width: 15%; 
}
.pdf-upload{
  width: 100px;
  height: 100px;
  background-color: red;
}
.pdf-upload-label{
  cursor: pointer;
  text-align: center;
  font-weight: normal;
  color: #333;
  background-color: #f9f9f9;
}

/* Media Queries for Smaller Screens */
@media (max-width: 768px) {
  .medical-records {
    margin: 10px;
  }

  .issue-tags {
    justify-content: space-around;
  }

  .history-item {
    flex-direction: column; /* Stack number and text vertically */
    align-items: flex-start;
  }

  .history-number {
    margin-bottom: 5px;
  }
}

@media (max-width: 480px) {
  .issue-tag {
    padding: 5px 8px;
    font-size: 12px;
  }

  .history-text {
    font-size: 12px;
  }
}


