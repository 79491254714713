/* RoomAssign.css */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
}

.room-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 32px;
}

.room-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff8f1;
  border-radius: 8px;
  padding: 16px;
  transition: all 0.2s ease-in-out;
}

.room-card:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.room-info {
  display: flex;
  align-items: center;
  gap: 16px;
}

.icon-container {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.room-icon {
  width: 32px;
  height: 32px;
  color: #92400e;
}

.room-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.room-id,
.room-name {
  display: flex;
  align-items: center;
  gap: 8px;
}

.label {
  font-weight: 500;
}

.name-value {
  color: #c2410c;
}

.appointment-counter {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #1e3a8a;
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
}

.count {
  font-size: 20px;
}

.info-icon {
  cursor: pointer;
  transition: transform 0.2s ease;
}

.info-icon:hover {
  transform: scale(1.1);
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.back-button,
.submit-button {
  padding: 8px 32px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.back-button {
  background-color: #1e3a8a;
}

.back-button:hover {
  background-color: #1e40af;
}

.submit-button {
  background-color: #fdba74;
}

.submit-button:hover {
  background-color: #fb923c;
}

@media (max-width: 768px) {
  .room-grid {
    grid-template-columns: 1fr;
  }

  .room-card {
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
  }

  .appointment-counter {
    justify-content: center;
  }
}
