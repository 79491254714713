:root {
  /* Background Colors */
  --bg-table: #FFFAF4;
  --bg-thead: #fbd9b3;
  --bg-btn-light: #f8f9fa;
  --bg-btn-light-hover: #e2e6ea;
  --bg-modal-body: #fcf8f4;
  --bg-option-box: #fef6ef;
  --bg-option-box-active: #ffe6cc;
  --bg-done-btn: #003366;
  --bg-done-btn-hover: #002244;
  --bg-dropdown: #fffaf4;
  --bg-dropdown-hover: #ffe6cc;
  --bg-delete-hover: #f9d6d5;

  /* Border Colors */
  --border-option-box: #f8e0d5;
  --border-option-box-active: #ff9933;

  /* Text Colors */
  --text-neutral: #6c757d;
  --text-neutral-hover: #343a40;
  --text-delete: #e74c3c;
  --text-btn-light: #fffaf4;
  --text-btn-light-hover: #fff3e4;

  /* Accent Colors */
  --accent-checkbox: #ff7e47;

  /* Shadows */
  --shadow-thead: 1px 1px 5px #b9b6b6;
  --shadow-option-box-active: 0 0 10px rgba(255, 153, 51, 0.5);
  --shadow-dropdown: 0 4px 6px rgba(0, 0, 0, 0.1);
}


/* Role config list  */
.table {
  width: 100%;
  overflow: visible;
  border-collapse: separate;
  border-spacing: 0 5px;
  position: relative;
}

.table thead {
  box-shadow: var(--shadow-thead);
  border-radius: 0.5rem;
}

.head-row th:last-child {
  text-align: right;
  border-radius: 0 0.5rem 0.5rem 0;
}

.head-row th:first-child {
  border-radius: 0.5rem 0 0 0.5rem;
}


.main-tr td{
  border: none;
  border-radius: 0.5rem;
}

.table th,
.table td {
  padding: 0.75rem;
  text-align: left;
  background-color: #FFFAF4;
}

.table thead th {
  background-color: #fbd9b3;
  font-weight: 600;
}

.table td {
  vertical-align: middle;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-light {
  color: #6c757d;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #343a40;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.table .btn {
  font-size: 1.2rem;
  padding: 0.25rem 0.5rem;
}

.table .btn-light {
  color: #fffaf4;
}

.table .btn-light:hover {
  color: #fff3e4;
}

.custom-modal-body {
  background-color: #fcf8f4;
  border-radius: 10px;
  padding: 20px;
}

.option-box {
  background-color: #fef6ef;
  border: 1px solid #f8e0d5;
  border-radius: 10px;
  padding: 20px;
  width: 100px;
  margin: 10px;
  cursor: pointer ;
  transition: transform 0.3s ease;
}

.option-box:hover {
  transform: scale(1.1);
}

.option-box.active {
  background-color: #ffe6cc;
  border-color: #ff9933;
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(255, 153, 51, 0.5);
}

.option-box .icon {
  font-size: 24px;
  margin-bottom: 5px;
}

.done-btn {
  background-color: #003366;
  color: white;
  border: none;
  padding: 10px 30px;
  border-radius: 5px;
}

.done-btn:hover {
  background-color: #002244;
}

.action-cell {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}

.dropdown-toggle {
  align-items: center;
  justify-content: center;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 150px;
  gap: 8px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  color: #242424;
  background-color: #F5F5F5;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.dropdown-item:hover {
  background-color: rgb(211, 211, 211);
  color: #2a2a2a;
}

.dropdown-item .icon {
  font-size: 16px;
}

.dropdown-item.delete {
  color: #e74c3c;
}

.dropdown-item.delete:hover {
  background-color: #f9d6d5;
}

.dropdown-menu {
  animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    /* transform: translateY(-10px); */
  }
  to {
    opacity: 1;
    /* transform: translateY(0); */
  }
}
