
.tabs button{
    width:auto;
    height: 2.4rem;
    margin-right: 2px;
    border-radius: 5px;
    padding-right: 15px;
    padding-left: 15px;
    font-weight: bold;
    font-size: 0.875em;
}
.active-request{
  background: #E3B075;
}

.patient-id-color{
  color: #B77240;
;
}

.admit-btn{
  color: #1599D1;
  border: 1px solid #1599D1;
  padding: .7rem 2rem;
  border-radius: 3px;
}

.admit-btn:hover{
  background-color: #1599d10c;
  /* color: #fff; */
}

.admitted-text{
  color: green;
}
/* .tabs-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 16px;
  }
  
  .tabs button {
    padding: 10px 20px;
    border: none;
    background-color: lightgray;
    cursor: pointer;
    font-size: 16px;
  }
  
  .tabs button.active {
    background-color: #007bff;
    color: white;
  }
  
  .tab-content {
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
  } */
  

  .dropdown-menu1 {
    position: absolute;
    right: 10px;
    top: 30px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 3;
  }
  
  .dropdown-menu1 button {
    display: block;
    width: 100%;
    text-align: left;
    padding: 8px;
    border: none;
    background: none;
    color: black;
    cursor: pointer;
  }
  
  .dropdown-menu1 button:hover {
    background-color: #f5f5f5;
  }
  